<template>
  <div id="category-slider">
    <v-slide-group multiple>
      <v-slide-item  v-for="list in slides"
        :key="list.id">
        <v-btn :input-value="fild_active == 'all' ? true: false" 
        outlined 
        :color="fild_active == 'all' ? '': 'primary'" class="button mx-1" active-class="primary white--text">
        <v-avatar size="25" class="ml-1">
          <v-img src="@/assets/logo.png"/>
        </v-avatar>
        {{list.title}}
        </v-btn>
      </v-slide-item>
    </v-slide-group>
  </div>
</template>
<script>
export default {
  props: ['slides'],
  data: () => ({
    model: null,
    fild_active: 'all'
  }),
  methods: {
  }
}
</script>